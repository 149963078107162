import React, { Component } from "react";

const CompareButton = (props) => {
	// const { compareMode } = props;
	return (
		<div className="text-center mt-4">
			<button className="btn btn-secondary btn-sm" onClick={() => window.location.reload()}>
				Làm khảo sát
			</button>
		</div>
	);
};

class Result extends Component {
	initialState = {
		searchName: this.props.searchName ? this.props.searchName : "",
		serverName: "",
		results: {},
	};

	state = this.initialState;

	handleChange = (event) => {
		const { value } = event.target;
		this.setState((prevState) => ({
			...prevState,
			searchName: value,
		}));
	};

	getResult = async () => {
		const fullname = this.state.searchName;
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				fullname: fullname,
			}),
		};
		const response = await fetch(
			process.env.REACT_APP_API_URL + "get-results.php",
			requestOptions
		);
		const data = await response.json();
		if (data.hasOwnProperty("error")) {
			alert(data.error);
			this.setState(this.initialState);
			return;
		}
		this.setState((prevState) => ({
			...prevState,
			results: data.success.results,
			serverName: data.success.serverName,
		}));
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.getResult();
	};

	componentDidMount = () => {
		if (this.state.searchName) {
			this.getResult();
		}
	};

	render() {
		return (
			<div className="row">
				{this.props.compareState ? (
					<Search
						searchName={this.state.searchName}
						handleChange={this.handleChange}
						handleSubmit={this.handleSubmit}
					/>
				) : (
					""
				)}

				{
					Object.keys(this.state.results).length > 0
						?
						(<Top3
							results={this.state.results}
							serverName={this.state.serverName}
							compareState={this.props.compareState}
						/>)
						:
						(<div className="spinner-border m-auto" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>)
				}
				
				{this.props.compareState ? (
					""
				) : (
					<CompareButton compareMode={this.props.compareMode} />
				)}
			</div>
		);
	}
}

const Search = (props) => {
	const { searchName, handleChange, handleSubmit } = props;
	return (
		<div className="col-12 mb-3">
			<form
				className="row row-cols-lg-auto g-3 align-items-center justify-content-center"
				onSubmit={handleSubmit}
			>
				<div className="col-12">
					<input
						type="text"
						name="fullname"
						id="fullname"
						placeholder="Họ và tên"
						className="form-control"
						value={searchName}
						required={true}
						onChange={handleChange}
					/>
				</div>
				<div className="col-12">
					<button type="submit" className="btn btn-primary">
						Search
					</button>
				</div>
			</form>
		</div>
	);
};

const Top3 = (props) => {
	const { results, serverName, compareState } = props;
	if (Object.keys(results).length <= 0) {
		return <div></div>;
	}

	const resultsArr = Object.keys(results).map((row_key) => {
		const columns = results[row_key].columns;
		const rowCount = Object.keys(columns).reduce((total, key) => {
			const options = columns[key].options;
			const checked = Object.keys(options).filter((index) => {
				return options[index].checked === true;
			});
			return total + checked.length;
		}, 0);
		return {
			id: results[row_key].id,
			label: results[row_key].label,
			job: results[row_key].job,
			description: results[row_key].description,
			count: rowCount,
		};
	});
	const renderTop3 = resultsArr
		.sort((a, b) => b.count - a.count)
		.slice(0, 3)
		.map((top) => {
			return (
				<div className="row px-3" key={top.id}>
					<div className="col-lg-3 box-column">
						<p>
							<strong>
								Total number checked in{" "}
								<span style={{ display: "inline-block" }}>
									{top.label}:
								</span>
							</strong>
						</p>
						<div className="box-score">{top.count}</div>
					</div>
					<div className="col-lg-4 box-column">
						<p>
							<strong>Career</strong>
						</p>
						<p>{top.job}</p>
					</div>
					<div className="col-lg-5 box-column">
						<p>
							<strong>Description</strong>
						</p>
						<p>{top.description}</p>
					</div>
				</div>
			);
		});

	return (
		<div className="col-12">
			<h2
				className={compareState ? `text-center mb-3 mt-3` : `mb-5 mt-5`}
			>
				{serverName}
				{compareState
					? ""
					: `, Your top three Career Clusters of interest are:`}
			</h2>
			{renderTop3}
		</div>
	);
};

export default Result;
