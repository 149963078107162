import Result from "./Result"

const SurveyButton = (props) => {
	// const {surveyMode} = props
	return (
		<div className="text-center mt-4 mb-4">
			<button className="btn btn-secondary btn-sm" onClick={() => window.location.reload()} >
				Làm khảo sát
			</button>
		</div>
	)
}

const Compare = (props) => {
	const {searchName, surveyMode, compareState} = props
	return(
		<div className="col-12">
			<h1 className="text-center mt-3 mb-2">Kết quả</h1>
			<div className="row">
				<div className="col-lg-12">
					<Result searchName={searchName} compareState={compareState} />
				</div>
				{/* <div className="col-lg-6">
					<Result compareState={compareState} />
				</div> */}
			</div>
			<SurveyButton surveyMode={surveyMode} />
		</div>
	)
}

export default Compare